import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

export const ContactUs = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sent, setSent] = useState(false);

  const sendEmail = (e) => {
    console.log("ciao");
    e.preventDefault();

    setDisabled(true);
    emailjs
      .send(
        "service_vw92d0r",
        "template_11r1xz2",
        { name, email, message },
        {
          publicKey: "B6vdScfFWnjcJP-nL",
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setSent(true);
        },
        (error) => {
          console.log("FAILED...", error);
          setDisabled(false);
        }
      );
  };

  return (
    <section class="contact_section layout_padding" id="contact">
      <div class="container">
        <div class="heading_container">
          <h2>Prova il nostro servizio</h2>
          <p>
            Chiedi ora di provare il nostro servizio! Compila il form qui sotto
            per essere ricontattato il prima possibile:
          </p>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8 mx-auto">
            <form ref={form}>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    id="form"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    disabled={disabled}
                    style={{
                      cursor: disabled ? "not-allowed" : "pointer",
                      ...(disabled && { opacity: 0.8 }),
                    }}
                  />
                </div>
                <div class="form-group col-md-6">
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    disabled={disabled}
                    style={{
                      cursor: disabled ? "not-allowed" : "pointer",
                      ...(disabled && { opacity: 0.8 }),
                    }}
                  />
                </div>
              </div>
              <div class="form-group">
                <textarea
                  type="text"
                  class="form-control"
                  id="inputMessage"
                  placeholder="Messaggio"
                  rows={5}
                  style={{
                    height: 100,
                    minHeight: 100,
                    maxHeight: 300,
                    cursor: disabled ? "not-allowed" : "pointer",
                    ...(disabled && { opacity: 0.8 }),
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                  disabled={disabled}
                />
              </div>
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  onClick={sendEmail}
                  disabled={disabled}
                  style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    ...(disabled && { opacity: 0.8 }),
                  }}
                >
                  {sent ? "Inviato!" : "Invia"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
